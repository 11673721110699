import axios from "@/utils/axios";
// 审核机器新增

const nodeHost = 'https://www.deeplink.cloud/dpb'
const testHost = 'http://localhost:8099/dpb/dpb'
const mainHost = 'https://www.deeplink.cloud'

// 获取Blog列表
export const getBlogList = (data) => {
  return axios({
    method: "post",
    url: nodeHost + '/blog/page/list',
    data: data
  })
}

// 获取Blog详细信息
export const getBlogInfo = (data) => {
  return axios({
    method: "post",
    url: nodeHost + '/blog/page/info',
    data: data
  })
}


// 获取faq列表
export const getFaqList = (data) => {
  return axios({
    method: "post",
    url: nodeHost + '/help/page/list',
    data: data
  })
}

// 获取faq详细信息
export const getFaqInfo = (data) => {
  return axios({
    method: "post",
    url: nodeHost + '/help/page/info',
    data: data
  })
}


// 获取国家列表
export const getCountlist = () => {
  return axios({
    method: "get",
    url: mainHost + '/api/pre/getCountryList' 
  });
};

// 获取用户数量
export const getUserNumber = () => {
  return axios({
    method: "get",
    url: mainHost + '/api/pre/getUserNumber' 
  });
};
