<template>
  <div class="coverage">
    <div class="coverage_cont">
      <div class="coverage_title">{{ $t('coverage.title') }}</div>
      <div class="coverage_text">{{ $t('coverage.text') }} {{ userNumber }}</div>
      <MapboxMap />
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref, onMounted ,computed } from "vue";
  import { useI18n } from "vue-i18n";
  import { useStore } from "vuex"
  import MapboxMap from './MapboxMap.vue';
  import { getUserNumber } from '@/api'
  export default defineComponent({
    name: 'coverage',
    components: {
      MapboxMap
    },
    setup() {
      const { t, locale } = useI18n()
      const store = useStore()
      let lan = computed(() => store.state.lan)
      const userNumber = ref(0)
      const getNumber = async () => {
        try {
          let countryData = await getUserNumber()
          if (countryData.success) {
            userNumber.value = countryData.content
          }
        } catch (error) {
          console.log(error.message);
        }
      }
      onMounted(async () => {
        await getNumber()
      });
      return {
        lan,
        userNumber
      };
    }
  })
</script>

<style scoped lang="scss">
.coverage {
  position: relative;
  max-width: 1200px;
  height: 800px;
  padding: 6px;
  border-radius: 20px;
  margin: 60px auto;
  box-sizing: border-box;
  background: linear-gradient(172.56deg, rgba(46, 81, 60, 0.8) -70.92%, rgba(46, 81, 60, 0.32) 129.41%);
  .coverage_cont {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 20px;
    box-sizing: border-box;
    // background: url(../assets/user_coverage_bg.png) no-repeat 100%/100%;
    .coverage_title {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      font-size: 30px;
      font-weight: 600;
      line-height: 1;
      text-align: center;
      margin: 20px 0 20px;
      letter-spacing: 0.05em;
      color: rgba(3, 255, 145, 1);
    }
    .coverage_text {
      position: absolute;
      top: 50px;
      left: 0;
      z-index: 5;
      width: 100%;
      font-size: 24px;
      font-weight: 600;
      line-height: 40px;
      text-align: center;
      margin: 20px 0 20px;
      letter-spacing: 0.05em;
      color: rgba(255, 255, 255, .9);
    }
  }
}

@media screen and (max-width: 1200px) {
  .coverage {
    width: 600px;
    height: 500px;
    .show {
      display: none;
    }
    .hide {
      display: block;
    }
    .coverage_cont {
      .coverage_title {
        font-size: 24px;
      }
      .coverage_text {
        font-size: 20px;
      }
    }
  }
}

</style>