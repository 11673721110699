<template>
  <div class="bgicon">
    <div class="icon1"></div>
    <div class="icon2"></div>
    <div class="icon3"></div>
    <div class="icon4"></div>
  </div>
</template>

<script>
  import { defineComponent, ref } from "vue";
  import Header from "@/components/header.vue";
  import Footer from "@/components/footer.vue";
  export default defineComponent({
    name: 'App',
    components: {
      Header,
      Footer
    }
  })
</script>

<style lang="scss">
.bgicon {
  width: 100%;
  z-index: 1;
  left: 50%;
  right: 50%;
  max-width: 1200px;
  position: absolute;
  margin-left: -600px;
  .icon1 {
    position: absolute;
    top: 19px;
    left: 500px;
    width: 96px;
    height: 90px;
    background: url(../assets/image_img/icon1.png) no-repeat 100%/100%;
  }
  .icon2 {
    position: absolute;
    top: 100px;
    right: 300px;
    width: 105px;
    height: 97px;
    background: url(../assets/image_img/icon2.png) no-repeat 100%/100%;
  }
  .icon3 {
    position: absolute;
    top: 300px;
    right: 100px;
    width: 99px;
    height: 92px;
    background: url(../assets/image_img/icon3.png) no-repeat 100%/100%;
  }
  .icon4 {
    position: absolute;
    top: 100px;
    left: 20px;
    width: 120px;
    height: 109px;
    background: url(../assets/image_img/icon4.png) no-repeat 100%/100%;
  }
}

</style>
