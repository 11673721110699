<template>
  <div class="cloudInternet" :class="{lan_En : lan != 'zh'}">
    <div class="page_cont1">
      <div class="page_title animation_hide" v-animate="{delay: 0, class:'amplify'}">{{ $t('cloudInternet.title') }}</div>
      <div class="page_text animation_hide" v-animate="{delay: 0, class:'amplify'}">{{ $t('cloudInternet.text') }}</div>
    </div>
    <div class="page page_cont2 animation_hide" v-animate="{delay: 0, class:'amplify'}">
      <div class="videoPaly">
        <video ref="videoRef" src="https://www.deepbrainchain.org/cloudInternet1.mp4"
          loop="loop" 
          muted="muted" 
          controls="controls"
          controlsList="noplaybackrate, nopip">
        </video>
      </div>
    </div>
    <div class="page page_cont3">
      <div class="page_title title animation_hide" v-animate="{delay: 0, class:'amplify'}">{{ $t('cloudInternet.cont2.text1') }} {{ $t('cloudInternet.cont2.text2') }}</div>
      <div class="page_text text animation_hide" v-animate="{delay: 0, class:'amplify'}">{{ $t('cloudInternet.cont2.text3') }}</div>
      <div class="page3_cont">
        <div class="block animation_hide" v-animate="{delay: 0, class:'fadeInUp'}">
          <div class="page_title block_title">{{ $t('cloudInternet.cont2.block1.text1') }}</div>
          <div class="page_text block_text">{{ $t('cloudInternet.cont2.block1.text2') }}</div>
        </div>
        <div class="block delay100 animation_hide" v-animate="{delay: 100, class:'fadeInUp'}">
          <div class="page_title block_title">{{ $t('cloudInternet.cont2.block2.text1') }}</div>
          <div class="page_text block_text">{{ $t('cloudInternet.cont2.block2.text2') }}</div>
        </div>
        <div class="block delay200 animation_hide" v-animate="{delay: 200, class:'fadeInUp'}">
          <div class="page_title block_title">{{ $t('cloudInternet.cont2.block3.text1') }}</div>
          <div class="page_text block_text">{{ $t('cloudInternet.cont2.block3.text2') }}</div>
        </div>
        <div class="block delay300 animation_hide" v-animate="{delay: 300, class:'fadeInUp'}">
          <div class="page_title block_title">{{ $t('cloudInternet.cont2.block4.text1') }}</div>
          <div class="page_text block_text">{{ $t('cloudInternet.cont2.block4.text2') }}</div>
        </div>
        <div class="block delay400 animation_hide" v-animate="{delay: 400, class:'fadeInUp'}">
          <div class="page_title block_title">{{ $t('cloudInternet.cont2.block5.text1') }}</div>
          <div class="page_text block_text">{{ $t('cloudInternet.cont2.block5.text2') }}</div>
        </div>
      </div>
    </div>
    <div class="page page_cont4">
      <div class="videoList animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
        <div class="video_media">
          <video ref="videoRef" src="https://www.deepbrainchain.org/cloudInternet1.mp4"
            loop="loop" 
            muted="muted" 
            controls="controls"
            controlsList="noplaybackrate, nopip">
          </video>
        </div>
      </div>
      <div class="videoList animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
        <div class="video_media">
          <video ref="videoRef" src="https://www.deepbrainchain.org/cloudInternet2.mp4"
            loop="loop" 
            muted="muted" 
            controls="controls"
            controlsList="noplaybackrate, nopip">
          </video>
        </div>
      </div>
    </div>
    <div class="page page_cont5 animation_hide" v-animate="{delay: 0, class:'amplify'}">
      <div class="gradient_text title">{{ $t('cloudInternet.cont4.text1') }}</div>
      <div class="page_title text">{{ $t('cloudInternet.cont4.text2') }}</div>
      <a class="btn" download="comparative_data.png" href="https://www.deepbrainchain.org/data.png">{{ $t('cloudInternet.cont4.text3') }}</a>
    </div>
    <div class="page page_cont6 animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
      <div class="page_title title">{{ $t('cloudInternet.cont5.text1') }}</div>
      <div class="page_text text">{{ $t('cloudInternet.cont5.text2') }}</div>
      <div class="gradient_text text1">{{ $t('cloudInternet.cont5.text3') }}</div>
      <div class="gradient_text text2">{{ $t('cloudInternet.cont5.text4') }}</div>
    </div>
    <div class="page page_cont7 animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
      <div class="page_title title">{{ $t('cloudInternet.cont5.text5') }}</div>
      <div class="page_text text">{{ $t('cloudInternet.cont5.text6') }}</div>
    </div>
    <div class="page page_cont8">
      <div class="page8_cont">
        <div class="cont_block animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="block_top">
            <div class="page_title title">{{ $t('cloudInternet.cont6.title') }}</div>
          </div>
          <div class="block_cont">
            <div class="btn_bg">
              <a download="Data_center_architecture.pdf" href="https://www.deepbrainchain.org/Data_center_architecture.pdf" class="block_btn"><p>{{ $t('cloudInternet.cont6.text3') }}</p><span></span></a>
            </div>
            <div class="btn_bg">
              <a download="HyconsCloud.ppsx" href="https://www.deepbrainchain.org/HyconsCloud.ppsx" class="block_btn"><p>{{ $t('cloudInternet.cont6.text1') }}</p><span></span></a>
            </div>
            <div class="btn_bg">
              <a download="Benefit_analysis.pdf" href="https://www.deepbrainchain.org/Benefit_analysis.pdf" class="block_btn"><p>{{ $t('cloudInternet.cont6.text2') }}</p><span></span></a>
            </div>
            <div class="btn_bg">
              <a download="DeepLink_Protocol.pdf" href="https://www.deepbrainchain.org/DeepLink_Protocol.pdf" class="block_btn"><p>{{ $t('cloudInternet.cont6.text5') }}</p><span></span></a>
            </div>
            <div class="btn_bg">
              <a download="Install_manual.pdf" href="https://www.deepbrainchain.org/Install_manual.pdf" class="block_btn"><p>{{ $t('cloudInternet.cont6.text4') }}</p><span></span></a>
            </div>
          </div>
        </div>
        <div class="cont_img animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <p class="page_title title">{{ $t('cloudInternet.cont5.text7') }}</p>
          <p class="gradient_text text">{{ $t('cloudInternet.cont5.text8') }}</p>
          <img class="icon" src="../assets/image_img/inter_page8_img.png" alt="image">
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'cloudInternet',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.cloudInternet {
  margin: auto;
  width: 100%;
  overflow: hidden;
  font-style: normal;
  position: relative;
  .page_title {
    color: #fff;
    line-height: 1.1;
    font-weight: 600;
    font-family: Monda;
  }
  .page_text {
    line-height: 1.1;
    font-family: Outfit;
    color: rgba(255, 255, 255, .5);
  }
  .gradient_text {
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #8000FF 10%, #00FFD1 100%); 
  }
  .page {
    position: relative;
    z-index: 10;
  }
  .page_cont1 {
    width: 100%;
    max-width: 1200px;
    position: relative;
    overflow: hidden;
    text-align:center;
    margin: 100px auto 200px;
    .page_title {
      width: 90%;
      margin: auto;
      font-size: 32px;
      margin-bottom: 30px;
    }
    .page_text {
      width: 90%;
      margin: auto;
      font-size: 16px;
      line-height: 30px;
    }
  }
  .page_cont2 {
    margin: 100px auto 200px;
    .videoPaly {
      width: 90%;
      margin: auto;
      max-width: 900px;
      video {
        width: 100%;
      }
    }
  }
  .page_cont3 {
    width: 100%;
    margin: auto;
    min-width: 600px;
    max-width: 1200px;
    text-align:center;
    .title {
      width: 90%;
      margin: auto;
      font-size: 32px;
      margin-bottom: 30px;
    }
    .text {
      width: 90%;
      margin: auto;
      font-size: 16px;
      line-height: 30px;
    }
    .page3_cont {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 60px;
      justify-content: space-around;
      .block {
        width: 340px;
        height: 321px;
        padding: 40px;
        display: flex;
        margin-bottom: 60px;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: space-between;
        background: url(../assets/image_img/inter_page3_bg.png) no-repeat 100%/100%;
        .block_title {
          font-size: 24px;
        }
        .block_text {
          font-size: 16px;
        }
      }
    }
  }
  .page_cont4 {
    position: relative;
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 100px 0;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-around;
    .videoList {
      width: 588px;
      padding: 40px;
      box-sizing: border-box;
      .video_title {
        color: #fff;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        line-height: normal;
        margin-bottom: 24px;
        letter-spacing: 0.48px;
      }
      .video_media {
        width: 508px;
        height: 280px;
        background: #000;
        video {
          width: 508px;
          height: 280px;
        }
      }
    }
  }
  .page_cont5 {
    width: 100%;
    min-width: 600px;
    max-width: 1200px;
    text-align: center;
    margin: 0 auto 200px;
    .title {
      width: 90%;
      margin: auto;
      font-size: 20px;
      font-family: Monda;
      margin-bottom: 30px;
    }
    .text {
      font-size: 32px;
      line-height: 1.45;
    }
    .btn {
      color: #fff;
      font-size: 20px;
      margin-top: 30px;
      font-family: Monda;
      padding: 25px 50px;
      border-radius: 14px;
      display: inline-block;
      text-decoration: none;
      background: linear-gradient(90deg, #8000FF 0%, #00FFD1 100%); 
    }
  }
  .page_cont6 {
    width: 90%;
    min-width: 600px;
    max-width: 1200px;
    text-align: center;
    margin: 0 auto 200px;
    .title {
      width: 90%;
      font-size: 32px;
      font-family: Monda;
      margin: 0 auto 30px;
    }
    .text {
      width: 90%;
      margin: 35px auto;
      font-size: 16px;
      line-height: 1.3;
    }
    .text1 {
      width: 90%;
      font-size: 20px;
      font-family: Monda;
      margin: 0 auto 30px;
    }
    .text2 {
      width: 90%;
      margin: auto;
      font-size: 40px;
      font-family: Monda;
    }
  }
  .page_cont7 {
    width: 90%;
    min-width: 600px;
    max-width: 1200px;
    padding: 40px 35px;
    text-align: center;
    border-radius: 14px;
    margin: 0 auto 200px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, .05);
    .title{
      font-size: 32px;
      margin-bottom: 35px;
    }
    .text {
      font-size: 16px;
      line-height: 1.8;
    }
  }
  .page_cont8 {
    position: relative;
    z-index: 2;
    width: 90%;
    margin: auto;
    min-width: 600px;
    max-width: 1200px;
    margin-bottom: 150px;
    .page8_cont {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 65px;
      box-sizing: border-box;
      align-items: flex-start;
      justify-content: space-around;
      .cont_img {
        display: flex;
        width: 454px;
        height: 425px;
        margin-bottom: 30px;
        flex-direction: column;
        background: url(../assets/image_img/inter_page8_bg.png) center;
        background-size: 570px 530px;
        .title {
          color: rgba(255, 255, 255, .5);
          font-size: 20px;
          margin: 15px 0;
        }
        .text {
          font-family: Monda;
          font-weight: 600;
          font-size: 32px;
        }
        img {
          width: 298px;
          height: 205px;
          margin: auto;
        }
      }
      .cont_block {
        flex: 1;
        display: flex;
        min-width: 600px;
        margin-right: 45px;
        margin-bottom: 30px;
        align-items: center;
        border-radius: 23px;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: space-between;
        .block_top {
          width: 100%;
          text-align: right;
          .title {
            font-size: 40px;
          }
        }
        .block_cont {
          display: flex;
          flex-wrap: wrap;
          margin-top: 40px;
          border-radius: 14px;
          box-sizing: border-box;
          flex-direction: row-reverse;
          justify-content: space-between;
          .btn_bg {
            padding:1px;
            margin-bottom: 20px;
            border-radius: 10px;
            background: linear-gradient(90deg, #8000FF 10%, #00FFD1 100%);
            .block_btn {
              display: flex;
              padding: 10px;
              border-radius: 10px;
              align-items: center;
              background: #0d1110;
              text-decoration: none;
              box-sizing: border-box;
              p {
                color: #FFF;
                font-size: 12px;
                font-weight: 600;
                font-family: Monda;
                letter-spacing: 1px;
              }
              span {
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-left: 10px;
                background: url(../assets/image_img/inter_download.png) no-repeat 100%/100%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .cloudInternet {
    .page_cont8 {
      .page8_cont {
        .cont_img {
          .title {
            text-align: center;
          }
          .text {
            text-align: center;
          }
        }
        .cont_block {
          .block_top {
            .title {
              text-align: center;
            }
          }
          .block_cont {
            justify-content: space-around;
          }
        }
      }
    }
  }
}
</style>
