<template>
  <div class="blogInfo" :class="{lan_En : lan != 'zh'}">
    <div class="page_cont">
      <div class="page_title">{{ blogData.title }}</div>
      <div class="page_text text"><p class="page_title1">{{ blogData.category }}</p> {{ new Date(blogData.updateTime).toLocaleString() }}</div>
      <div class="page_img"><img :src="`https://www.deeplink.cloud${blogData.overview}`"/></div>
      <div class="page_text" v-html="blogData.content"></div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, ref, computed, watch, onMounted } from "vue";
  import { useRoute, useRouter } from 'vue-router'
  import { getBlogInfo } from "@/api/index.js"
  import { useI18n } from "vue-i18n";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'BlogInfo',
    setup() {
      const { t, locale } = useI18n();
      const store = useStore()
      let lan = computed(() => {
        return store.state.lan
      })
      const router = useRouter()
      const route = useRoute()
      const blogData = ref('')
      const getData = async () => {
        const data = await getBlogInfo({
          lang: lan.value,
          _id: route.query.id
        })
        if (data.success) {
          blogData.value = data.data
        }
      }
      onMounted(async() => {
        await getData()
      })
      return {
        lan,
        blogData,
      };
    }
  })
</script>

<style>
.blogInfo p {
  margin: revert;
}
.blogInfo span {
  margin: revert;
}
.blogInfo div {
  margin: revert;
}
.blogInfo li {
  margin: revert;
}
</style>

<style lang="scss" scoped>
.blogInfo {
  margin: auto;
  width: 100%;
  overflow: hidden;
  font-style: normal;
  position: relative;
  .page_title {
    color: #fff;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    font-family: Monda;
  }
  .page_title1 {
    font-size: 24px;
    line-height: 1.1;
    font-weight: 600;
    font-family: Outfit;
    color: rgba(255, 255, 255, .8);
  }
  .page_text {
    font-size: 16px;
    line-height: 1.1;
    font-family: Outfit;
    color: rgba(255, 255, 255, .5);
  }
  .page_cont {
    position: relative;
    z-index: 2;
    width: 90%;
    min-width: 600px;
    overflow: hidden;
    max-width: 1100px;
    margin: 100px auto;
    position: relative;
    .text {
      display: flex;
      margin-top: 20px;
      align-items: baseline;
      .page_title1 {
        margin-right: 10px;
      }
    }
    .page_img {
      width: 100%;
      margin: 30px 0;
      overflow: hidden;
      border-radius: 50px;
      img {
        width: 100%;
      }
    }
    .page_text {
      width: 100%;
    }
  }
}

</style>

