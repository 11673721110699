import axios from 'axios'
// import Cookies from "js-cookie";

const instance = axios.create()
instance.interceptors.request.use(config => {
  return config
}, error => {
  return Promise.reject(error)
  // console.log(error.message);
})

instance.interceptors.response.use(res => {
  return res.data
}, error => {
  if (error.response && error.response.status === 503) {
    // 处理 503 错误
    return Promise.reject({
      success: false,
      code: -1,
      message: 'request timeout'
    });
    // 你可以根据实际需要进行进一步的处理，比如显示错误消息、跳转到错误页面等
  }
  if (error.response && error.response.status === 413) {
    // 处理 413 错误
    return Promise.reject({
      success: false,
      code: -1,
      message: 'The image upload size exceeds the limit, please reduce the image size'
    })
    // 你可以根据实际需要进行进一步的处理，比如显示错误消息、跳转到错误页面等
  }
  if (error.response && error.response.status === 502) {
    // 处理 413 错误
    return Promise.reject({
      success: false,
      code: -1,
      message: 'Network Error - Bad Gateway'
    })
    // 你可以根据实际需要进行进一步的处理，比如显示错误消息、跳转到错误页面等
  }
  return Promise.reject({
    success: false,
    code: -1,
    message: error.message
  })
  // return Promise.reject(error);
})

export default instance
