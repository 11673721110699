<template>
  <div class="reward" :class="{lan_En : lan != 'zh'}">
    <div class="content">
      <!-- <img class="bg" src="../assets/reward_bg2.png" alt=""> -->
      <div class="title_text">基本能力</div>
      <div class="desc">DeepLink目前测试最高可以达到8k分辨率下 60fps 或4k分辨率 240fps的稳定串流能力，显示延迟局域网可以做到10ms左右， 具体测试方案可参考b站视频。广域网可以做到15-20%丢包下的勉强可玩。</div>
      <div class="title_text">常见问题</div>
      <div class="cont">
        <div class="details min_h124">
          <div class="number">1</div>
          <div class="title">rtt、fps不稳定怎么办？</div>
          <div>如果lost也跟着不稳定，尝试降低码率，找到⼀个合适的码率。如果lost正常，请将wifi使用5g网络。还是不行的话，请自行排除本身网络问题，确认网络没问题的话，可以wireshark抓包发给我们。</div>
        </div>
        <div class="details min_h124">
          <div class="number">2</div>
          <div class="title">画面不清晰？</div>
          <div>带宽有限的情况下， 目前的策略是优先保证帧率提升操作性， 后续会有版本优先保证清晰度</div>
        </div>
        <div class="details min_h146">
          <div class="number">3</div>
          <div class="title">⽆损模式流畅度降低？</div>
          <div>无损模式对编解码显卡有要求：编码端需要nvidia 1650及以上， 或者11代intel以上显卡，解码端需要nvidia 2060及以上， 或者10代intel以上显卡。如果硬件不支持，就会使用cpu进行编解码， 流畅度就会降低</div>
        </div>
        <div class="details min_h146">
          <div class="number">4</div>
          <div class="title">文件复制粘贴怎么使用？</div>
          <div>直接ctrl+c ,ctrl+v复制粘贴即可， 广域网基本可以跑满限速，局域网400m。</div>
        </div>
      </div>
      <div class="title_text">使用优化</div>
      <div class="cont">
        <div class="details min_h124">
          <div class="number">1</div>
          <div class="title">显示RELAY</div>
          <div>relay指远控过程中，被控端和主控端之间的所有音视频数据，都要经过DeepLink的中继服务器， 所以这里我们会有一些限码率、限制fps的策略。</div>
          <div>
            <p>可以使用以下任⼀⽅法保证直连：</p>
            <p>1.主控端、被控端都开启IPv6</p>
            <p>2.主控端 或 被控端任意⼀边有公⽹IP，在这⼀端的路由器开启UPNP、NAT-PMP、PCP任意⼀个</p>
            <p>3.主控端 或 被控端任意⼀边有公⽹IP，在这⼀端的路由器设置UDP端⼝映射</p>
          </div>
          <div>尽量不要使用第三方的vpn软件， 目前已知的vpn没有一款打洞能力比我们更强， 另外使用这些传输协议也会受到影响， 我们的抗弱网策略基本都会失效。</div>
        </div>
        <div class="details min_h124">
          <div class="number">2</div>
          <div class="title">显示CPU编解码 </div>
          <div>
            <p>2018年之后的cpu，gpu我们都测试完成， 只要更新显卡驱动到官⽹最新版就可以。</p>
            <p>更老一点的我们没有完全测试， 请更新驱动自行尝试。</p>
          </div>
          <div>
            <p>查看log: %appdata%/quicdesk/log/video开头的log，看看错误内容</p>
            <p>比如nvencoder64.dll 缺失，重装驱动或者c:\windows\system32下dir/s nvenc*.dll，找到后复制到c:\windows\system32下即可</p>
          </div>
        </div>
      </div>
      <div class="title_text">控制相关</div>
      <div class="cont">
        <div class="details min_h124">
          <div class="number">1</div>
          <div class="title">鼠标只能移动，不能控制 </div>
          <div>大部分情况是因为360杀软导致， 请暂时关掉360，或者使用其他杀软</div>
        </div>
        <div class="details min_h124">
          <div class="number">2</div>
          <div class="title">游戏内键鼠失效</div>
          <div>
            <p>呼出顶部菜单， 其他->游戏键鼠 选中， 会使用hid驱动来模拟键鼠</p>
            <p>另外一些游戏有anticheat, 比如valorant， 我们目前不支持，其他有问题可以直接给我们发个问题视频， 和游戏下载地址</p>
          </div>
        </div>
        <div class="details min_h124">
          <div class="number">3</div>
          <div class="title">手柄无法使用</div>
          <div>
            <p>在控制机上⾯打开 控制⾯板-硬件和声⾳-查看设备和打印机（win11）。确认设备⾥⾯，已经识别到⼿柄。</p>
            <p>打开https://gamepad-tester.com/ 测试键位是否正常，控制机正常后，远程连接到被控机，打开https://gamepad-tester.com/ 测试键位是否正常</p>
            <p>备注：ps4手柄连接上后请选择xbox模式</p>
          </div>
        </div>
        <div class="details min_h124">
          <div class="number">4</div>
          <div class="title">左上角名词解释</div>
          <div> <img src="../assets/question1.png" alt=""> </div>
          <div>
            <p>DESKTOP为被控机器的电脑名称</p>
            <p>P2P为 本次使用的p2p连接， 相对应的是RELAY,代表走了我们的中继服务器， 走中继会有限速和限制fps的问题， 不同级别的用户限制会不一样</p>
            <p>HEVC 代表本次协商的编码协议， 目前总共有HEVC, AVC两种， AV1在添加中</p>
            <p>GPU:GPU 代表本次使用的是GPU编解码， 另外还可能出现CPU, 代表使用CPU编解码， 性能会有下降</p>
          </div>
        </div>
        <div class="details min_h124">
          <div class="number">5</div>
          <div class="title">右下角名词解释</div>
          <div> <img src="../assets/question2.png" alt=""> </div>
          <div>FPS 当前帧率，RTT 网络延时，LOST 网络丢包率</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, computed } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'Reward',
    setup() {
      const store = useStore()
      let lan = computed(() => {
        return store.state.lan
      })
      return {
        lan
      };
    }
  })
</script>

<style lang="scss" scoped>
.reward {
  width: 100%;
  font-style: normal;
  // font-family: PingFang SC;
  .content {
    position: relative;
    z-index: 2;
    width: 100%;
    min-width: 1200px;
    font-size: 0;
    line-height: 0;
    .title_text {
      color: #FFF;
      font-size: 30px;
      font-weight: 600;
      padding: 50px 0 0;
      text-align: center;
      line-height: normal;
    }
    .desc {
      width: 1200px;
      color: #FFF;
      font-size: 24px;
      padding: 0 20px;
      margin: 30px auto;
      text-align: center;
      line-height: normal;
      box-sizing: border-box;
    }
    .cont {
      position: relative;
      width: 1200px;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      font-weight: 500;
      padding: 30px 0 0;
      box-sizing: border-box;
      justify-content: space-between;
      .details {
        position: relative;
        width: 590px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 20px;
        border-radius: 12px;
        box-sizing: border-box;
        padding: 20px 17px 20px 54px;
        color: rgba(54, 63, 94, 0.8);
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.352) 0%, rgba(255, 255, 255, 0.88) 67.57%);
        .number {
          position: absolute;
          top: 20px;
          left: 20px;
          width: 24px;
          height: 24px;
          color: #FFF;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          border-radius: 100%;
          background: rgba(54, 63, 94, 0.8);
        }
        .title {
          font-size: 18px;
          font-weight: 400;
          color: #363F5E;
          line-height: 24px;
          margin-bottom: 10px;
        }
      }
      .min_h124 {
        min-height: 124px;
      }
      .min_h146 {
        min-height: 124px;
      }
      .min_h215 {
        min-height: 124px;
      }
    }
  }
}

@media screen and (max-width: 1200px){
  .reward {
    width: 100%;
    min-width: 600px;
    .content {
      position: relative;
      margin-top: -2px;
      min-width: 600px;
      width: 100%;
      font-size: 0;
      line-height: 0;
      height: inherit;
      background: linear-gradient(180deg, #363F5E 52.12%, rgba(54, 63, 94, 0.9) 100%);
      .show {
        display: block;
      }
      .hide {
        display: none;
      }
      .desc {
        width: 100%;
      }
      .cont {
        position: relative;
        margin: auto;
        width: 80%;
        padding-top: 40px;
        text-align: left;
        justify-content: center;
        .icon1 {
          display: none;
        }
        .icon2 {
          display: none;
        }
        p {
          margin-bottom: 5px;
        }
        .desc1 {
          font-weight: 600;
          font-size: 24px;
          line-height: 42px;
          color: #FFFFFF;
        }
        .desc2 {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 20px;
          color: rgba(255, 255, 255, 0.88);
        }
        .desc3 {
          width: 420px;
          height: 120px;
          margin: auto;
          font-weight: 600;
          padding-top: 27px;
          text-align: center;
          box-sizing: border-box;
          background: url(../assets/tree.png) no-repeat 100%/100%;
          .tip1 {
            color: #FFF;
            font-size: 24px;
            line-height: 33px;
          }
          .tip2 {
            font-size: 18px;
            line-height: 25px;
            color: #44FF55;
          }
        }
        .line {
          position: absolute;
          top: 250px;
          left: 0;
          right: 0;
          width: 3px;
          margin: auto;
          height: 50px;
          background: url(../assets/line.png) no-repeat 100%/100%;
        }
        .branch {
          position: relative;
          width: 64%;
          height: 0;
          margin: 50px auto 0;
          border-top: 2px dashed rgba(4, 180, 116, 0.68);
        }
        .tree {
          position: relative;
          width: 100%;
          display: flex;
          margin: auto;
          flex-wrap: wrap;
          justify-content: center;
          .leaf {
            width: 28%;
            margin: 0 2%;
            text-align: center;
            &.leaf2 {
              margin-top: -300px;
            }
            .line1 {
              width: 3px;
              margin: auto;
              height: 51px;
              background: url(../assets/line.png) no-repeat 100%/100%;
            }
            .line2 {
              height: 330px;
              background-repeat: repeat-y;
            }
            .icon {
              width: 52px;
              height: 52px;
              margin-bottom: 15px;
            }
            .text {
              width: 100%;
              height: 180px;
              padding: 10px;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              text-align: justify;
              border-radius: 12px;
              box-sizing: border-box;
              border: 1px solid rgba(255, 255, 255, 0.45);
              color: rgba(255, 255, 255, 0.6);
              background: linear-gradient(0deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.016) 96.01%);
              .title {
                width: 100%;
                height: 40px;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #44FF55;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}

</style>

