<template>
  <div class="gameFi" :class="{lan_En : lan != 'zh'}">
    <div class="page_cont1">
      <div class="page_title animation_hide" v-animate="{delay: 0, class:'amplify'}">{{ $t('gameFi.title1') }}</div>
    </div>
    <div class="page page_cont2">
      <div class="page_cont">
        <div class="block delay100 animation_hide" v-animate="{delay: 100, class:'fadeInUp'}">
          <div class="page_text block_text">{{ $t('gameFi.block1.text1_1') }}</div>
        </div>
        <div class="block delay200 animation_hide" v-animate="{delay: 200, class:'fadeInUp'}">
          <div class="page_text block_text">{{ $t('gameFi.block1.text1_2') }}</div>
        </div>
        <div class="block delay300 animation_hide" v-animate="{delay: 300, class:'fadeInUp'}">
          <div class="page_text block_text">{{ $t('gameFi.block1.text1_3') }}</div>
        </div>
      </div>
    </div>
    <div class="page page_cont3">
      <div class="page3_bg"></div>
      <div class="page3_cont">
        <!-- <div class="page_title title">{{ $t('gameFi.block1.text2') }}</div> -->
        <div class="page3_block">
          <div class="block animation_hide" v-animate="{delay: 0, class:'fadeInUp'}">
            <div class="page_text block_text">{{ $t('gameFi.block1.text2_1') }}</div>
          </div>
          <div class="block delay100 animation_hide" v-animate="{delay: 100, class:'fadeInUp'}">
            <div class="page_text block_text">{{ $t('gameFi.block1.text2_2') }}</div>
          </div>
          <div class="block delay200 animation_hide" v-animate="{delay: 200, class:'fadeInUp'}">
            <div class="page_text block_text">{{ $t('gameFi.block1.text2_3') }}</div>
          </div>
          <div class="block delay300 animation_hide" v-animate="{delay: 300, class:'fadeInUp'}">
            <div class="page_text block_text">{{ $t('gameFi.block1.text2_4') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="page page_cont4 animation_hide" v-animate="{delay: 0, class:'amplify'}">
      <div class="gradient_text title">{{ $t('gameFi.title2') }}</div>
      <div class="question_list">
        <div class="question_li" v-for="(item, index) in question" :key="index">
          <div class="li_top" :class="{ 'ques_show': item.show }" @click="item.show = !item.show">
            <p>{{ item.qs }}</p>
            <div class="icon"></div>
          </div>
          <div v-show="item.show" class="li_bottom">
            <p>{{ item.as }} <span v-if="index == 4">service@deeplink.cloud</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, computed, ref, onMounted, watch } from "vue";
  import { useI18n } from "vue-i18n";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'gameFi',
    setup() {
      const store = useStore()
      let lan = computed(() => {
        return store.state.lan
      })
      const { t, locale } = useI18n();
      const question = ref([
        {
          qs: t(`gameFi.block2.text1_1`),
          as: t(`gameFi.block2.text1_2`),
          show: false
        },
        {
          qs: t(`gameFi.block2.text2_1`),
          as: t(`gameFi.block2.text2_2`),
          show: false
        },
        {
          qs: t(`gameFi.block2.text3_1`),
          as: t(`gameFi.block2.text3_2`),
          show: false
        },
        {
          qs: t(`gameFi.block2.text4_1`),
          as: t(`gameFi.block2.text4_2`),
          show: false
        },
        {
          qs: t(`gameFi.block2.text5_1`),
          as: t(`gameFi.block2.text5_2`),
          show: false
        }
      ])
      const chooseQuestin = (val) => {
        index.value = val
        text.value = t(`gameFi.block2.text${val}_2`)
      }
      watch(lan, (newvlaue, oldvalue) => {
        question.value = [
          {
            qs: t(`gameFi.block2.text1_1`),
            as: t(`gameFi.block2.text1_2`)
          },
          {
            qs: t(`gameFi.block2.text2_1`),
            as: t(`gameFi.block2.text2_2`)
          },
          {
            qs: t(`gameFi.block2.text3_1`),
            as: t(`gameFi.block2.text3_2`)
          },
          {
            qs: t(`gameFi.block2.text4_1`),
            as: t(`gameFi.block2.text4_2`)
          },
          {
            qs: t(`gameFi.block2.text5_1`),
            as: t(`gameFi.block2.text5_2`)
          }
        ]
      })
      onMounted(() => {
      })
      return {
        lan,
        question,
        chooseQuestin
      };
    }
  })
</script> 

<style lang="scss" scoped>
.gameFi {
  margin: auto;
  width: 100%;
  overflow: hidden;
  font-style: normal;
  position: relative;
  .page_title {
    color: #fff;
    line-height: 1.1;
    font-weight: 600;
    font-family: Monda;
  }
  .page_text {
    line-height: 1.1;
    font-family: Outfit;
    color: rgba(255, 255, 255, .5);
  }
  .gradient_text {
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #8000FF 37%, #00FFD1 60%); 
  }
  .page {
    position: relative;
    z-index: 10;
  }
  .page_cont1 {
    width: 100%;
    max-width: 1200px;
    position: relative;
    overflow: hidden;
    text-align:center;
    margin: 100px auto 100px;
    .page_title {
      width: 90%;
      margin: auto;
      font-size: 32px;
      margin-bottom: 30px;
    }
  }
  .page_cont2 {
    width: 100%;
    margin: auto;
    min-width: 600px;
    max-width: 1200px;
    text-align:center;
    .page_cont {
      display: flex;
      flex-wrap: wrap;
      margin-top: 60px;
      justify-content: space-around;
      .block {
        width: 32%;
        min-width: 250px;
        padding: 40px;
        text-align: center;
        margin-bottom: 30px;
        box-sizing: border-box;
        border-radius: 0 0 27px 27px;
        background: url(../assets/image_img/inter_page3_bg.png) no-repeat 100%/100%;
        background-position: top;
        .block_text {
          font-size: 16px;
          line-height: 31px;
        }
      }
    }
  }
  .page_cont3 {
    width: 100%;
    margin: auto;
    overflow: hidden;
    min-width: 600px;
    max-width: 1200px;
    text-align:center;
    border-radius: 31px;
    box-sizing: border-box;
    margin:  60px auto 200px;
    border: 1px solid rgba(255, 255, 255, .25);
    .page3_bg {
      position: absolute;
      top: -55px;
      left: -30px;
      z-index: 1;
      width: 283px;
      height: 285px;
      background: url(../assets/image_img/gameFi_page3_bg.png) no-repeat 100%/100%;
    }
    .page3_cont {
      width: 100%;
      height: 100%;
      padding: 60px 20px;
      box-sizing: border-box;
      background: rgba(255, 255, 255, .05);
      position: relative;
      z-index: 2;
      backdrop-filter: blur(45px);
      .title {
        margin: auto;
        font-size: 32px;
        margin-bottom: 30px;
      }
      .text {
        margin: auto;
        font-size: 16px;
        line-height: 30px;
      }
      .page3_block {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        // margin-top: 60px;
        justify-content: space-around;
        .block {
          width: 24%;
          max-width: 246px;
          padding: 40px 20px;
          display: flex;
          // margin-bottom: 60px;
          align-items: center;
          flex-direction: column;
          box-sizing: border-box;
          border-radius: 0 0 20px 20px;
          justify-content: space-between;
          background: url(../assets/image_img/inter_page3_bg.png) no-repeat 100%/100%;
          background-position: top;
          .block_title {
            font-size: 24px;
          }
          .block_text {
            font-size: 16px;
          }
        }
      }
    }
  }
  .page_cont4 {
    width: 100%;
    max-width: 1200px;
    position: relative;
    overflow: hidden;
    text-align:center;
    margin: 100px auto 100px;
    .title {
      width: 90%;
      margin: auto;
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 60px;
    }
    .question_list {
      width: 100%;
      .question_li {
        width: 100%;
        padding: 20px;
        margin-bottom: 25px;
        border-radius: 14px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, .05);
        border: 1px solid rgba(255, 255, 255, .11);
        .li_top {
          width: 100%;
          height: 100%;
          padding: 40px;
          display: flex;
          cursor: pointer;
          border-radius: 14px;
          transition: 1s ease;
          align-items: center;
          box-sizing: border-box;
          justify-content: space-between;
          &.ques_show {
            transition: 1s ease;
            border-radius: 14px;
            background: linear-gradient(90deg, #8000FF 10%, #00FFD1 100%);
            .icon {
              transition: 1s ease;
              background: url(../assets/image_img/gameFi_page5_icon2.png) no-repeat 100%/100%;
            }
          }
          p {
            color: #fff;
            font-size: 20px;
            text-align: left;
            line-height: 1.1;
            font-weight: 600;
            font-family: Monda;
          }
          .icon {
            width: 23px;
            height: 14px;
            transition: 1s ease;
            background: url(../assets/image_img/gameFi_page5_icon1.png) no-repeat 100%/100%;
          }
        }
        .li_bottom {
          width: 95%;
          font-size: 16px;
          text-align: left;
          font-family: Outfit;
          margin: 25px auto 0;
          transition: 1s ease;
          color: rgba(255, 255, 255, .5);
        }
      }
    }
  }
}
</style>

