<template>
  <div class="blog" :class="{lan_En : lan != 'zh'}">
    <!-- <div class="page page_cont1">
      <div class="page_bg1"></div>
      <div class="page_bg2"></div>
      <div class="page_bg3"></div>
      <div class="page_cont">
        <div class="featured">Featured</div>
        <div class="cont_text"><a href="https://www.deeplink.cloud/" target="_blank">{{ atricle }}</a></div>
      </div>
    </div> -->
    <div class="page page_cont2">
      <div class="page_title title">Categories</div>
      <div class="page_cont">
        <div class="meau_li" :class="{active: choose == 'All'}" @click="chooseReport('All')">All</div>
        <div class="meau_li" :class="{active: choose == 'News'}" @click="chooseReport('News')">News</div>
        <div class="meau_li" :class="{active: choose == 'Development'}" @click="chooseReport('Development')">Development</div>
        <div class="meau_li" :class="{active: choose == 'Update'}" @click="chooseReport('Update')">Update</div>
        <div class="meau_li" :class="{active: choose == 'Product'}" @click="chooseReport('Product')">Product</div>
        <div class="meau_li" :class="{active: choose == 'Community'}" @click="chooseReport('Community')">Community</div>
      </div>
    </div>
    <div class="page page_cont3">
      <div v-if="show_report.length" class="page_cont">
        <div class="blog_cont animation_hide" v-animate="{delay: 0, class:'fadeInUp'}" v-for="(item, index) in show_report" :key="index" @click="showInfo(item.id)">
          <div class="top_img">
            <img :src="`https://www.deeplink.cloud${item.overview}`"/>
          </div>
          <div class="top_title">{{ item.title }}</div>
          <div class="top_text">{{ item.desc }}</div>
          <div class="bottom_text">
            <p>{{ new Date(item.updatedAt).toLocaleString() }}</p>
            <p>{{ item.category }}</p>
          </div>
        </div>
      </div>
      <div v-if="!show_report.length && show_nodata" class="nodata">
        Waiting for updates...
      </div>
      <div class="pagination">
        <el-pagination
          :hide-on-single-page="true"
          :page-size="9"
          :total="total"
          :background="false"
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, ref, computed, watch, onMounted, inject } from "vue";
  import { getBlogList } from "@/api/index.js"
  import { useI18n } from "vue-i18n";
  import { useStore } from "vuex"
  import { useRoute, useRouter } from 'vue-router'
  export default defineComponent({
    name: 'Blog',
    setup() {
      const { t, locale } = useI18n();
      const store = useStore()
      const loading = inject( '$loading')
      let lan = computed(() => {
        return store.state.lan
      })
      const router = useRouter()
      const route = useRoute()
      const choose = ref('All')
      const total = ref(0)
      const current = ref(1)
      console.log(lan.value, 'lan');
      const lanObj = ref([ 'en', 'zh', 'ko', 'ja', 'ru', 'vn', 'es', 'fr', 'de', 'tr' ])
      const atricle = ref('')
      const show_report = ref([])
      const chooseReport = async (type) => {
        choose.value = type
        await getData(current.value)
      }
      const data_loading = ref(null)
      const show_nodata = ref(false)
      const getData = async (pageNum) => {
        if (data_loading.value) {
          data_loading.value.close()
          data_loading.value = null
        }
        data_loading.value = loading.service({target: '.page_cont3', background: 'rgba(122, 122, 122, 0.1)', text: 'loading...'})
        show_report.value = []
        show_nodata.value = false
        try {
          const data = await getBlogList({
            pageSize: 9,
            pageNum: pageNum,
            lang: lan.value,
            category: choose.value
          })
          if (data.success) {
            total.value = data.data.total
            show_report.value = data.data.list.map(item => ({
              id: item._id,
              title: item.title.All || item.title[lan.value],  // 选择 "All" 或 "en" 字段
              desc: item.desc.All || item.desc[lan.value],    // 选择 "All" 或 "en" 字段
              overview: item.overview.All || item.overview[lan.value],
              updateTime: item.updateTime.All || item.updateTime[lan.value],
              category: item.category.All || item.category[lan.value],
              state: item.state.All || item.state[lan.value],
              updatedAt: item.updatedAt
            }));
          }
          data_loading.value.close()
          if (!show_report.value.length) {
            show_nodata.value = true
          }
        } catch (error) {
          data_loading.value.close()
          if (!show_report.value.length) {
            show_nodata.value = true
          }
        }
      }
      const handleCurrentChange = async (val) => {
        current.value = val
        await getData(current.value)
      }
      const showInfo = (el) => {
        router.push({path: '/blogInfo', query: {id: el}})
      }
      watch(
        () => locale.value,
        () => {
          getData(current.value)
        }
      )
      onMounted(async () => {
        await getData(current.value)
        // show_report.value = report.value
      })
      return {
        lan,
        choose,
        atricle,
        total,
        show_nodata,
        show_report,
        chooseReport,
        showInfo,
        handleCurrentChange
      };
    }
  })
</script>
<style>
.el-pager li {
  color: #fff !important;
  background-color: transparent !important;
}
.el-pager li.is-active {
  color: #409eff !important;
}
.el-pagination button:disabled {
  background-color: transparent !important;
}
.el-pagination .btn-next, .el-pagination .btn-prev {
  color: #fff !important;
  background-color: transparent !important;
}
</style>
<style lang="scss" scoped>
.blog {
  margin: auto;
  width: 100%;
  overflow: hidden;
  font-style: normal;
  position: relative;
  .page_title {
    color: #fff;
    line-height: 1.1;
    font-weight: 600;
    font-family: Monda;
  }
  .page_text {
    line-height: 1.1;
    font-family: Outfit;
    color: rgba(255, 255, 255, .5);
  }
  .gradient_text {
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #8000FF 37%, #00FFD1 60%); 
  }
  .page {
    position: relative;
    z-index: 10;
  }
  .page_cont1 {
    width: 100%;
    height: 520px;
    min-width: 600px;
    overflow: hidden;
    max-width: 1200px;
    margin: 100px auto;
    position: relative;
    border-radius: 36px;
    background: #000403;
    border: 1px solid rgba(255, 255, 255, .2);
    .page_bg1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 690px;
      height: 517px;
      background: url(../assets/image_img/blog_page_bg1.png) no-repeat 100%/100%;
    }
    .page_bg2 {
      position: absolute;
      z-index: 1; 
      bottom: 0;
      right: 0;
      width: 478px;
      height: 437px;
      opacity: 0.3;
      background: url(../assets/image_img/blog_page_bg2.png) no-repeat 100%/100%;
    }
    .page_bg3 {
      position: absolute;
      z-index: 2;
      bottom: 0;
      right: 0;
      width: 940px;
      height: 650px;
      background: url(../assets/image_img/blog_page_bg3.png) no-repeat 100%/100%;
    }
    .page_cont {
      position: relative;
      z-index: 5;
      width: 100%;
      height: 100%;
      .featured {
        color: #03FF91;
        font-size: 24px;
        margin: 27px 30px;
        padding: 10px 30px;
        font-family: Outfit;
        border-radius: 14px;
        display: inline-block;
        background: rgba(255, 255, 255, .1)
      }
      .cont_text {
        position:absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 50px;
        font-size: 48px;
        font-weight: 600;
        border-radius: 28px;
        font-family: Outfit;
        box-sizing: border-box;
        background: rgba(255, 255, 255, .1);
        a {
          color: #fff;
          text-decoration: none;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 3; //行数
          line-clamp: 3;
          -webkit-box-orient: vertical; //盒子中内容竖直排列
        }
      }
    }
  }
  .page_cont2 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    min-width: 600px;
    max-width: 1200px;
    text-align:center;
    position: relative;
    padding: 25px 50px;
    align-items: center;
    border-radius: 19px;
    margin: 0 auto 100px;
    box-sizing:  border-box;
    background: rgba(255, 255, 255, .1);
    .title {
      font-size: 36px;
      margin-right: 30px;
    }
    .page_cont {
      flex: 1;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      min-width: 600px;
      align-items: center;
      .meau_li {
        padding: 20px;
        font-size: 16px;
        cursor: pointer;
        font-family: Monda;
        transition: ease 1s;
        box-sizing: border-box;
        color: rgba(255, 255, 255, .5);
        border-bottom: 1px solid transparent;
        &:hover {
          color: #fff;
          border-bottom: 1px solid #07F2D4;
        }
        &.active {
          color: #fff;
          border-bottom: 1px solid #07F2D4;
        }
      }
    }
  }
  .page_cont3 {
    width: 100%;
    overflow: hidden;
    min-width: 600px;
    min-height: 500px;
    max-width: 1200px;
    position: relative;
    margin: 0 auto 100px;
    box-sizing:  border-box;
    .page_cont {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .blog_cont {
        position: relative;
        width: 32%;
        cursor: pointer;
        margin-right: 2%;
        transition: ease 1s;
        border-radius: 33px;
        margin-bottom: 40px;
        padding: 15px 15px 40px;
        box-sizing:  border-box;
        background: rgba(255, 255, 255, .05);
        border: 1px solid rgba(255, 255, 255, .1);
        &:hover {
          .top_title {
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-image: linear-gradient(90deg, #8000FF 37%, #00FFD1 60%); 
          }
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
        .top_img {
          width: 100%;
          height: 162px;
          display: flex;
          overflow: hidden;
          border-radius: 33px;
          align-items: center;
          margin-bottom: 25px;
          justify-content: center;
          background: url(../assets/image_img/blog_page2_bg.png) no-repeat 100%/100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .top_title {
          width: 100%;
          height: 95px;
          color: #fff;
          font-size: 24px;
          font-weight: 600;
          line-height: 30px;
          font-family: Outfit;
          margin-bottom: 15px;
          text-decoration: none;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 3; //行数
          line-clamp: 3;
          -webkit-box-orient: vertical; //盒子中内容竖直排列
        }
        .top_text {
          width: 100%;
          font-size: 16px;
          line-height: 30px;
          font-family: Outfit;
          text-decoration: none;
          color: rgba(255, 255, 255, .5);
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 5; //行数
          line-clamp: 5;
          -webkit-box-orient: vertical; //盒子中内容竖直排列
        }
        .bottom_text {
          width: calc(100% - 30px);
          position: absolute;
          bottom: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          color: rgba(255, 255, 255, .3);
        }
      }
    }
    .nodata {
      color: #fff;
      height: 500px;
      display: flex;
      font-size: 36px;
      font-weight: 600;
      font-family: Outfit;
      align-items: center;
      justify-content: center;
    }
    .pagination {
      margin: auto;
      width: max-content;
    }
  }
}

@media screen and (max-width: 900px) {
  .blog {
    .page_cont2  {
      .page_cont  {
        .meau_li {
          padding: 20px 10px;
        }
      }
    }
    .page_cont3 {
      .page_cont {
        .blog_cont {
          width: 49%;
          &:nth-child(3n) {
            margin-right: 2%;
          }
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
    }
  }
  
}

</style>

