<template>
  <div class="Faq" :class="{lan_En : lan != 'zh'}">
    <div class="page page_cont">
      <div class="gradient_text title">DeepLink Q&A</div>
      <div class="question_list">
        <div class="question_li" v-for="(item, index) in question" :key="index">
          <div class="li_top" :class="{ 'ques_show': item.show }" @click="item.show = !item.show">
            <p>{{ item.title }}</p>
            <div class="icon"></div>
          </div>
          <div v-show="item.show" class="li_bottom">
            <div v-html="item.desc"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, computed, ref, onMounted, watch, inject } from "vue";
  import { getFaqList, getFaqInfo } from "@/api/index.js"
  import { useI18n } from "vue-i18n";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'Faq',
    setup() {
      const store = useStore()
      const loading = inject( '$loading')
      let lan = computed(() => {
        return store.state.lan
      })
      const { t, locale } = useI18n();
      const question = ref([
        {
          qs: t(`gameFi.block2.text1_1`),
          as: t(`gameFi.block2.text1_2`),
          show: false
        },
        {
          qs: t(`gameFi.block2.text2_1`),
          as: t(`gameFi.block2.text2_2`),
          show: false
        },
        {
          qs: t(`gameFi.block2.text3_1`),
          as: t(`gameFi.block2.text3_2`),
          show: false
        },
        {
          qs: t(`gameFi.block2.text4_1`),
          as: t(`gameFi.block2.text4_2`),
          show: false
        },
        {
          qs: t(`gameFi.block2.text5_1`),
          as: t(`gameFi.block2.text5_2`),
          show: false
        }
      ])
      const chooseQuestin = (val) => {
        
      }
      const data_loading = ref(null)
      const getData = async () => {
        if (data_loading.value) {
          data_loading.value.close()
          data_loading.value = null
        }
        data_loading.value = loading.service({target: '.question_list', background: 'rgba(122, 122, 122, 0.1)', text: 'loading...'})
        question.value = []
        // show_nodata.value = false
        try {
          const data = await getFaqList({
            lang: lan.value
          })
          console.log(data, 'data');
          if (data.success) {
            question.value = data.data.map(item => ({
              id: item._id,
              title: item.title.All || item.title[lan.value],  // 选择 "All" 或 "en" 字段
              desc: item.content.All || item.content[lan.value],    // 选择 "All" 或 "en" 字段
              updatedAt: item.updatedAt,
              show: false
            }));
            console.log(question.value, 'question.value');
          }
          data_loading.value.close()
          // if (!show_report.value.length) {
          //   show_nodata.value = true
          // }
        } catch (error) {
          data_loading.value.close()
          // if (!show_report.value.length) {
          //   show_nodata.value = true
          // }
        }
      }
      watch(lan, async (newvlaue, oldvalue) => {
        await getData()
      })
      onMounted(() => {
        getData()
      })
      return {
        lan,
        question,
        chooseQuestin
      };
    }
  })
</script> 
<style>
.Faq p {
  margin: revert;
}
.Faq span {
  margin: revert;
}
.Faq div {
  margin: revert;
}
.Faq li {
  margin: revert;
}
</style>
<style lang="scss" scoped>
.Faq {
  z-index: 2;
  margin: auto;
  width: 100%;
  overflow: hidden;
  font-style: normal;
  position: relative;
  .page_title {
    color: #fff;
    line-height: 1.1;
    font-weight: 600;
    font-family: Monda;
  }
  .page_text {
    line-height: 1.1;
    font-family: Outfit;
    color: rgba(255, 255, 255, .5);
  }
  .gradient_text {
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #8000FF 37%, #00FFD1 60%); 
  }
  .page {
    position: relative;
    z-index: 10;
  }
  .page_cont {
    width: 100%;
    max-width: 1200px;
    position: relative;
    overflow: hidden;
    text-align:center;
    margin: 50px auto 100px;
    .title {
      width: 90%;
      margin: auto;
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 60px;
    }
    .question_list {
      width: 100%;
      min-height: 400px;
      .question_li {
        width: 100%;
        padding: 20px;
        margin-bottom: 25px;
        border-radius: 14px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, .05);
        border: 1px solid rgba(255, 255, 255, .11);
        .li_top {
          width: 100%;
          height: 100%;
          padding: 20px;
          display: flex;
          cursor: pointer;
          border-radius: 14px;
          transition: 1s ease;
          align-items: center;
          box-sizing: border-box;
          justify-content: space-between;
          &.ques_show {
            transition: 1s ease;
            border-radius: 14px;
            background: linear-gradient(90deg, #8000FF 10%, #00FFD1 100%);
            .icon {
              transition: 1s ease;
              background: url(../assets/image_img/gameFi_page5_icon2.png) no-repeat 100%/100%;
            }
          }
          p {
            margin: 0;
            color: #fff;
            font-size: 20px;
            text-align: left;
            line-height: 1.1;
            font-weight: 600;
            font-family: Monda;
          }
          .icon {
            width: 23px;
            height: 14px;
            transition: 1s ease;
            background: url(../assets/image_img/gameFi_page5_icon1.png) no-repeat 100%/100%;
          }
        }
        .li_bottom {
          width: 95%;
          font-size: 16px;
          line-height: 1.5;
          text-align: left;
          font-family: Outfit;
          margin: 25px auto 0;
          transition: 1s ease;
          color: rgba(255, 255, 255, .5);
        }
      }
    }
  }
}
</style>

