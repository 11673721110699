<template>
  <div class="nft" :class="{lan_En : lan != 'zh'}">
    <div class="page page_cont1">
      <div class="page_bg"></div>
      <div class="page_title animation_hide" v-animate="{delay: 0, class:'amplify'}">{{$t('nft.cont.title1')}}</div>
      <div class="page_text animation_hide" v-animate="{delay: 0, class:'amplify'}">
        <p>{{$t('nft.cont.desc1')}}</p>
        <p>{{$t('nft.cont.desc2')}}</p>
        <p>{{$t('nft.cont.desc3')}}</p>
        <p>{{$t('nft.cont.desc4')}}</p>
      </div>
    </div>
    <div class="page page_cont2">
      <div class="page_title title animation_hide" v-animate="{delay: 0, class:'amplify'}">{{$t('nft.enabl.title1')}}</div>
      <div class="page_cont">
        <div class="text_block animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="icon"></div>
          <div class="text">{{$t('nft.enabl.desc2')}}</div>
        </div>
        <div class="text_block animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="icon"></div>
          <div class="text">{{$t('nft.enabl.desc3')}}</div>
        </div>
        <div class="text_block animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="icon"></div>
          <div class="text">{{$t('nft.enabl.desc4')}}</div>
        </div>
        <div class="text_block animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="icon"></div>
          <div class="text">{{$t('nft.enabl.desc5')}}</div>
        </div>
        <div class="text_block animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="icon"></div>
          <div class="text">{{$t('nft.enabl.desc6')}}</div>
        </div>
        <div class="text_block animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="icon"></div>
          <div class="text">{{$t('nft.enabl.desc7')}}</div>
        </div>
        <div class="text_block animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="icon"></div>
          <div class="text">{{$t('nft.enabl.desc1')}}</div>
        </div>
      </div>
    </div>
    <div class="page page_cont3">
      <div class="ver animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
        <div class="ver_desc">
          <img class="nft_img" src="../assets/image_img/nft_page_img1.png" alt="">
          <div class="nft_info">
            <div class="info">
              <p class="title">{{$t('nft.edition.title1')}}</p>
              <p class="tip">{{$t('nft.edition.tip')}}</p>
            </div>
            <p class="price"><span>$</span>72</p>
          </div>
        </div> 
      </div>
      <div class="ver animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
        <div class="ver_desc">
          <img class="nft_img" src="../assets/image_img/nft_page_img2.png" alt="">
          <div class="nft_info">
            <div class="info">
              <p class="title">{{$t('nft.edition.title2')}}</p>
              <p class="tip color1">{{$t('nft.edition.tip')}}</p>
            </div>
            <p class="price"><span>$</span>360</p>
          </div>
        </div> 
      </div>
    </div>
    <div class="page page_cont4">
      <div class="page_cont">
        <div class="list" v-for="(el, index) in data" :key="index">
          <div class="li">{{el.li1}}</div>
          <div class="li" v-if="!el.li2"><span class="icon"></span></div>
          <!-- <div class="li" v-if="el.li2 == ('1' || '2')">{{el.li2}}</div> -->
          <div class="li" v-else-if="el.li2 == '1' && el.li5">1 <br/> {{el.li5}}</div>
          <div class="li" v-else-if="el.li2 == '2' && el.li5">2 <br/> {{el.li5}}</div>
          <div class="li" v-else>{{el.li2}}</div>
          <div class="li" v-if="!el.li3"><span class="icon"></span></div>
          <div class="li" v-else>{{el.li3}}</div>
          <div class="li" v-if="!el.li4"><span class="icon"></span></div>
          <div class="li" v-else>{{el.li4}}</div>
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
  import { defineComponent, ref, computed, watch } from "vue";
  import { useI18n } from "vue-i18n";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'NFT',
    setup() {
      const { t, locale } = useI18n();
      const store = useStore()
      let lan = computed(() => {
        return store.state.lan
      })
      const data = ref([
        {
          li1: t('nft.edition.list.li1_1'),
          li2: t('nft.edition.list.li1_2'),
          li3: t('nft.edition.list.li1_3'),
          li4: t('nft.edition.list.li1_4')
        },
        {
          li1: t('nft.edition.list.li2_1'),
          li2: '1080p 60fps',
          li3: '4k 240fps',
          li4: '4k 240fps'
        },
        // {
        //   li1: t('nft.edition.list.li3_1'),
        //   li2: t('nft.edition.list.li3_2'),
        //   li3: t('nft.edition.list.li3_3'),
        //   li4: t('nft.edition.list.li3_3'),
        // },
        // {
        //   li1: t('nft.edition.list.li4_1'),
        //   li2: t('nft.edition.list.li4_2'),
        //   li3: t('nft.edition.list.li4_3'),
        //   li4: t('nft.edition.list.li4_4'),
        // },
        // {
        //   li1: t('nft.edition.list.li5_1'),
        //   li2: t('nft.edition.list.li5_2'),
        //   li3: t('nft.edition.list.li5_3'),
        //   li4: t('nft.edition.list.li5_3'),
        // },
        {
          li1: t('nft.edition.list.li6_1'),
          li2: '1',
          li3: '1',
          li4: '5',
          li5: t('nft.edition.list.new_text1')
        },
        {
          li1: t('nft.edition.list.li26_1'),
          li2: '2',
          li3: '20',
          li4: '25',
          li5: t('nft.edition.list.new_text2')
        },
        {
          li1: t('nft.edition.list.li7_1'),
          li2: '200',
          li3: '300',
          li4: '1000'
        },
        {
          li1: t('nft.edition.list.li8_1'),
          li2: false,
          li3: false,
          li4: false
        },
        // {
        //   li1: t('nft.edition.list.li9_1'),
        //   li2: false,
        //   li3: false,
        //   li4: false
        // },
        // {
        //   li1: t('nft.edition.list.li10_1'),
        //   li2: false,
        //   li3: false,
        //   li4: false
        // },
        {
          li1: t('nft.edition.list.li12_1'),
          li2: false,
          li3: false,
          li4: false
        },
        {
          li1: t('nft.edition.list.li14_1'),
          li2: false,
          li3: false,
          li4: false
        },
        {
          li1: t('nft.edition.list.li15_1'),
          li2: false,
          li3: false,
          li4: false
        },
        {
          li1: t('nft.edition.list.li27_1'),
          li2: false,
          li3: false,
          li4: false
        },
        {
          li1: t('nft.edition.list.li13_1'),
          li2: '-',
          li3: false,
          li4: false
        },
        {
          li1: t('nft.edition.list.li16_1'),
          li2: '-',
          li3: false,
          li4: false
        },
        {
          li1: t('nft.edition.list.li11_1'),
          li2: '-',
          li3: false,
          li4: false
        },
        {
          li1: t('nft.edition.list.li17_1'),
          li2: '-',
          li3: false,
          li4: false
        },
        {
          li1: t('nft.edition.list.li18_1'),
          li2: '-',
          li3: false,
          li4: false
        },
        // {
        //   li1: t('nft.edition.list.li19_1'),
        //   li2: '-',
        //   li3: '-',
        //   li4: false
        // },
        // {
        //   li1: t('nft.edition.list.li20_1'),
        //   li2: '-',
        //   li3: '-',
        //   li4: false
        // },
        // {
        //   li1: t('nft.edition.list.li21_1'),
        //   li2: '-',
        //   li3: '-',
        //   li4: false
        // },
        // {
        //   li1: t('nft.edition.list.li22_1'),
        //   li2: '-',
        //   li3: '-',
        //   li4: false
        // },
        // {
        //   li1: t('nft.edition.list.li23_1'),
        //   li2: '-',
        //   li3: '-',
        //   li4: false
        // },
        // {
        //   li1: t('nft.edition.list.li24_1'),
        //   li2: '-',
        //   li3: '-',
        //   li4: false
        // },
        // {
        //   li1: t('nft.edition.list.li25_1'),
        //   li2: '-',
        //   li3: '-',
        //   li4: '-'
        // }
      ])
      watch(
        () => locale.value,
        () => {
          data.value = [
            {
              li1: t('nft.edition.list.li1_1'),
              li2: t('nft.edition.list.li1_2'),
              li3: t('nft.edition.list.li1_3'),
              li4: t('nft.edition.list.li1_4')
            },
            {
              li1: t('nft.edition.list.li2_1'),
              li2: '1080p 60fps',
              li3: '4k 240fps',
              li4: '4k 240fps'
            },
            // {
            //   li1: t('nft.edition.list.li3_1'),
            //   li2: t('nft.edition.list.li3_2'),
            //   li3: t('nft.edition.list.li3_3'),
            //   li4: t('nft.edition.list.li3_3'),
            // },
            // {
            //   li1: t('nft.edition.list.li4_1'),
            //   li2: t('nft.edition.list.li4_2'),
            //   li3: t('nft.edition.list.li4_3'),
            //   li4: t('nft.edition.list.li4_4'),
            // },
            // {
            //   li1: t('nft.edition.list.li5_1'),
            //   li2: t('nft.edition.list.li5_2'),
            //   li3: t('nft.edition.list.li5_3'),
            //   li4: t('nft.edition.list.li5_3'),
            // },
            {
              li1: t('nft.edition.list.li6_1'),
              li2: '1',
              li3: '1',
              li4: '5',
              li5: t('nft.edition.list.new_text1')
            },
            {
              li1: t('nft.edition.list.li26_1'),
              li2: '2',
              li3: '20',
              li4: '25',
              li5: t('nft.edition.list.new_text2')
            },
            {
              li1: t('nft.edition.list.li7_1'),
              li2: '200',
              li3: '300',
              li4: '1000'
            },
            {
              li1: t('nft.edition.list.li8_1'),
              li2: false,
              li3: false,
              li4: false
            },
            // {
            //   li1: t('nft.edition.list.li9_1'),
            //   li2: false,
            //   li3: false,
            //   li4: false
            // },
            // {
            //   li1: t('nft.edition.list.li10_1'),
            //   li2: false,
            //   li3: false,
            //   li4: false
            // },
            {
              li1: t('nft.edition.list.li12_1'),
              li2: false,
              li3: false,
              li4: false
            },
            {
              li1: t('nft.edition.list.li14_1'),
              li2: false,
              li3: false,
              li4: false
            },
            {
              li1: t('nft.edition.list.li15_1'),
              li2: false,
              li3: false,
              li4: false
            },
            {
              li1: t('nft.edition.list.li27_1'),
              li2: false,
              li3: false,
              li4: false
            },
            {
              li1: t('nft.edition.list.li13_1'),
              li2: '-',
              li3: false,
              li4: false
            },
            {
              li1: t('nft.edition.list.li16_1'),
              li2: '-',
              li3: false,
              li4: false
            },
            {
              li1: t('nft.edition.list.li11_1'),
              li2: '-',
              li3: false,
              li4: false
            },
            {
              li1: t('nft.edition.list.li17_1'),
              li2: '-',
              li3: false,
              li4: false
            },
            {
              li1: t('nft.edition.list.li18_1'),
              li2: '-',
              li3: false,
              li4: false
            },
            // {
            //   li1: t('nft.edition.list.li19_1'),
            //   li2: '-',
            //   li3: '-',
            //   li4: false
            // },
            // {
            //   li1: t('nft.edition.list.li20_1'),
            //   li2: '-',
            //   li3: '-',
            //   li4: false
            // },
            // {
            //   li1: t('nft.edition.list.li21_1'),
            //   li2: '-',
            //   li3: '-',
            //   li4: false
            // },
            // {
            //   li1: t('nft.edition.list.li22_1'),
            //   li2: '-',
            //   li3: '-',
            //   li4: false
            // },
            // {
            //   li1: t('nft.edition.list.li23_1'),
            //   li2: '-',
            //   li3: '-',
            //   li4: false
            // },
            // {
            //   li1: t('nft.edition.list.li24_1'),
            //   li2: '-',
            //   li3: '-',
            //   li4: false
            // },
            // {
            //   li1: t('nft.edition.list.li25_1'),
            //   li2: '-',
            //   li3: '-',
            //   li4: '-'
            // }
          ]
        }
      )
      return {
        lan,
        data
      };
    }
  })
</script>

<style lang="scss" scoped>
.nft {
  margin: auto;
  width: 100%;
  overflow: hidden;
  font-style: normal;
  position: relative;
  .page_title {
    color: #fff;
    line-height: 1.1;
    font-weight: 600;
    font-family: Monda;
  }
  .page_text {
    line-height: 1.1;
    font-family: Outfit;
    color: rgba(255, 255, 255, .5);
  }
  .gradient_text {
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #8000FF 37%, #00FFD1 60%); 
  }
  .page {
    position: relative;
    z-index: 10;
  }
  .page_cont1 {
    width: 100%;
    position: relative;
    overflow: hidden;
    text-align:center;
    padding: 100px 0 200px;
    background: url(../assets/image_img/nft_page_bg1.png) no-repeat 100%/100%;
    background-size: 800px 600px;
    background-position: center;
    .page_bg {
      position: absolute;
      top: 80px;
      left: 50%;
      right: 50%;
      width: 399px;
      height: 367px;
      margin-left: -200px;
      background: url(../assets/image_img/nft_page_bg2.png) no-repeat 100%/100%;
    }
    .page_title {
      width: 90%;
      margin: auto;
      font-size: 32px;
      max-width: 1200px;
      margin-bottom: 30px;
    }
    .page_text {
      width: 90%;
      color: #fff;
      margin: auto;
      max-width: 1200px;
      font-size: 16px;
      line-height: 32px;
      font-family: Outfit;
    }
  }
  .page_cont2 {
    width: 100%;
    max-width: 1200px;
    position: relative;
    overflow: hidden;
    text-align:center;
    margin: 0 auto 100px;
    .title {
      width: 90%;
      margin: auto;
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 60px;
    }
    .page_cont {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .text_block {
        margin: 20px;
        display: flex;
        padding: 30px;
        align-items: center;
        border-radius: 14px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, .05);
        border: 1px solid rgba(255, 255, 255, .11);
        .icon {
          width: 40px;
          height: 40px;
          margin-right: 30px;
          background: url(../assets/image_img/nft_page_img3.png) no-repeat 100%/100%;
        }
        .text {
          color: #fff;
          font-size: 16px;
          font-family: Monda;
        }
      }
    }
  }
  .page_cont3 {
    width: 100%;
    max-width: 1200px;
    position: relative;
    overflow: hidden;
    text-align: center;
    margin: 0 auto 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    .ver {
      width: 500px;
      display: flex;
      margin-bottom: 50px;
      align-items: center;
      border-radius: 30px;
      box-sizing: border-box;
      .ver_desc {
        display: flex;
        align-items: center;
        .nft_img {
          width: 120px;
          height: 120px;
          margin-right: -28px;
        }
        .nft_info {
          width: 400px;
          height: 90px;
          display: flex;
          border-radius: 12px;
          align-items: center;
          box-sizing: border-box;
          padding: 20px 25px 20px 40px;
          background: rgba(255, 255, 255, .05);
          .info {
            flex: 1;
            text-align: left;
            .title {
              color: #fff;
              font-size: 24px;
              font-weight: 600;
              line-height: 1.3;
              font-family: Monda;
            }
            .tip {
              color: #08C170;
              margin-top: 5px;
              font-size: 16px;
              line-height: 1.3;
              font-family: Outfit;
              &.color1 {
                color: #BE6CFF;
              }
            }
          }
          .price {
            color: #fff;
            font-weight: 600;
            font-family: Outfit;
            display: flex;
            font-size: 36px;
            line-height: 50px;
            align-items: center;
            span {
              color: #fff;
              font-size: 36px;
              font-weight: 600;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .page_cont4 {
    width: 100%;
    max-width: 1200px;
    position: relative;
    overflow: hidden;
    text-align:center;
    margin: 200px auto 200px;
    .page_cont {
      width: 100%;
      display: flex;
      overflow: hidden;
      border-radius: 8px;
      flex-direction: column;
      .list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .li {
          color: #fff;
          font-family: Outfit;
          width: 25%;
          padding: 15px 20px;
          box-sizing: border-box;
          line-height: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          text-align: center;
          box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.27);
          .icon {
            width: 36px;
            height: 36px;
            display: inline-block;
            vertical-align: middle;
            background: url(../assets/image_img/nft_page_img3.png) no-repeat 100%/100%;
          }
        }
        &:first-child {
          .li {
            height: 56px;
            font-size: 20px;
            box-shadow: none;
            font-weight: 600;
            background: rgba(255, 255, 255, .05);
          }
        }
      }
    }
  }
}

</style>

