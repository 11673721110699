<template>
  <div ref="globeContainer" class="globe-container"></div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, watch } from 'vue';
import { getCountlist } from '@/api'
import Globe from 'globe.gl';
import { useStore } from "vuex"
import earth_night from "@/assets/country/earth-night.jpg"
import ball_bg1 from "@/assets/country/ball_bg1.png"
export default {
  setup() {
    const store = useStore()
    let lan = computed(() => store.state.lan)
    const globeContainer = ref(null);
    let globe = null;
    const getHtml = (icon, name, name_en, num)=> {
      return `
      <div class="mapboxDataCon">
        <div class="mapboxDataPoint"><div class="point"></div></div>
        <div class="mapboxData ${num < 1000 ? 'hide' : ''}">
          <div class="MapboxImg "> <img src="${getImageUrl(icon)}" alt=""> </div>
          <div class="MapboxInfo">
            <div class="title"> ${lan.value == 'zh' ? name : name_en}</div>
            <div class="text"> ${num}</div>
          </div>
        </div>
      </div>
      ` 
    }
    let countryData = []
    const ElementsData = ref([])
    const getCountry = async () => {
      try {
        countryData = await getCountlist()
      } catch (error) {
        console.log(error.message);
      }
      if (countryData.success) {
        const returnData = []
        let country_Data = countryData.content&&countryData.content.length?countryData.content:[]
        country_Data.map(el => {
          if (!(el.lat == 0 && el.lon ==0)) {
            returnData.push({
              id: el.idx,
              lat: el.lat,
              lng: el.lon, 
              html: getHtml(el.name_abbr, el.name, el.name_en, el.num) 
            })
          }
        })
        ElementsData.value = returnData
      }
    }
    const getImageUrl = (imageName) => {
      try {
        return require(`@/assets/country/${imageName}.png`);
      } catch (err) {
        return require(`@/assets/country/default.png`)
      }
    }
    const screenWidth = ref(1200)
    const getData = async () => {
      try {
        screenWidth.value = window.innerWidth;
        await getCountry()
      } catch (error) {
        console.log(error.message)
      }
    }
    watch(lan, (newvlaue, oldvalue) => {
      ElementsData.value = []
      let returnData = []
      countryData.content&&countryData.content.length&&countryData.content.map(el => {
        if (!(el.lat == 0 && el.lon ==0)) {
          returnData.push({
            id: el.idx,
            lat: el.lat,
            lng: el.lon, 
            html: getHtml(el.name_abbr, el.name, el.name_en, el.num) 
          })
        }
      })
      ElementsData.value = returnData
      globe
        .htmlElementsData(ElementsData.value)
        .htmlElement(d => {
          const div = document.createElement('div');
          div.innerHTML = d.html;
          div.style.cursor = 'pointer';
          div.style['pointer-events'] = 'auto';
          return div;
        })
    })
    onMounted( async () => {
      await getData()
      // 初始化Globe
      globe = Globe()(globeContainer.value)
        .width((screenWidth.value < 1200) ? 588 : 1188)
        .height((screenWidth.value < 1200) ? 900 : 1500)
        .globeImageUrl(earth_night)
        .backgroundImageUrl(ball_bg1)
        .htmlElementsData(ElementsData.value)
        .htmlElement(d => {
          const div = document.createElement('div');
          div.innerHTML = d.html;
          div.style.cursor = 'pointer';
          div.style['pointer-events'] = 'auto';
          return div;
        })
      globe.controls().minDistance = (screenWidth.value < 1200) ? 380 : 300
      globe.controls().maxDistance = (screenWidth.value < 1200) ? 380 : 300
      globe.pointOfView({ lat: 25.333698, lng: 51.229529 }, 1000);
    });

    onUnmounted(() => {
      // 清理
      // globe && globe.dispose();
    });

    return {
      globeContainer,
    };
  },
};
</script>

<style>
.globe-container {
  height: 600px; /* 设置Globe的高度 */
  width: 100%;
}

.mapboxDataCon {
  position: relative;
}
.mapboxDataCon .mapboxDataPoint {
  width: 10px;
  height: 10px;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  background: rgb(3, 255, 145);
}
.mapboxDataCon:hover .mapboxData {
  display: flex !important;
}
.mapboxDataCon .mapboxDataPoint .point {
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 100%;
}
.mapboxData {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  box-sizing: border-box;
  border-radius: 25px 25px 0 25px;
  justify-content: space-between;
  background: rgba(150, 150, 150, .6);
}
.mapboxData.hide {
  display: none;
}
.mapboxData .MapboxImg {
  margin-right: 10px;
}
.mapboxData .MapboxImg img {
  width: 36px;
  /* height: 24px; */
}
.mapboxData .MapboxInfo {
  display: flex;
  text-align: left;
  align-items: center;
  flex-direction: column;
}
.mapboxData .MapboxInfo .title {
  width: 100%;
  color: #fff;
  font-size: 16px;
  margin-bottom: 5px;
  white-space: nowrap;
}
.mapboxData .MapboxInfo .text {
  width: 100%;
  font-size: 14px;
  color: rgb(3, 255, 145);
}


@media screen and (max-width: 1200px) {
  .mapboxData {
    padding: 5px 10px;
    border-radius: 16px 16px 0 16px;
  }
  .mapboxData .MapboxImg {
    margin-right: 5px;
  }
  .mapboxData .MapboxImg img {
    width: 24px;
  }
  .mapboxData .MapboxInfo .title {
    font-size: 14px;
  }
  .mapboxData .MapboxInfo .text {
    font-size: 12px;
  }
}
</style>
